<template>
  <v-card>
    <v-card>
      <v-toolbar
        dark
        color="primary"
      >
        <v-toolbar-title>
          Adicionar Produto
        </v-toolbar-title>
        <v-spacer />
        <v-btn
          class="mx-2"
          small
          fab
          color="light-green accent-4"
          @click="onAddProduto"
        >
          <v-icon class="white--text">
            mdi-plus
          </v-icon>
        </v-btn>
        <v-btn
          class="mx-2"
          small
          fab
          color="amber"
          @click="onSair"
        >
          <v-icon class="white--text">
            mdi-exit-to-app
          </v-icon>
        </v-btn>
      </v-toolbar>
    </v-card>
    <v-card>
      <v-container>
        <v-row dense>
          <v-col cols="12">
            <span class="primary--text"> {{ dados.descricao }}</span><strong class="primary--text pr-1 pl-3">Preço Unit.:</strong><strong class="pl-2">R$ {{ formatPrice(vlUnitarioBanco) }}</strong>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col
            cols="6"
            md="3"
          >
            <!-- Adicionar o Disabled, retirado a fins de Teste! -->
            <v-text-field
              ref="txtValorUnitarioAjuste"
              v-model="valorUnitarioPorCentagem"
              v-currency="currencyAjuste"
              label="Vl.Unit."
              dense
              outlined
              :disabled="disabledAjuste"
              @blur="validarAjustePorValorUnitario"
            />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col
            cols="6"
            md="3"
          >
            <span
              v-if="dados.vlPesound === 0"
              class="primary--text"
            >Unidade: {{ dados.cdUnidade }}</span>
            <v-select
              v-else
              v-model="unidadeSelecionanda"
              :items="selectUnidade"
              label="Unidade"
              dense
              outlined
              hide-details
              @change="onSelectUnidade"
            />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col
            cols="6"
            md="2"
          >
            <!-- Adicionar o Disabled, retirado a fins de Teste! -->
            <v-text-field
              ref="txtValorAjuste"
              v-model="valorajuste"
              v-currency="currencyAjuste"
              label="Ajuste"
              dense
              outlined
              :error-messages="errorAjuste"
              :disabled="disabledAjuste"
              @blur="validarAjuste"
            />
          </v-col>
          <v-col
            cols="6"
            md="3"
          >
            <v-text-field
              ref="txtQtd"
              v-model="qtd"
              v-currency="currency"
              label="Qtd."
              dense
              outlined
              autofocus
            />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col
            cols="12"
            class="d-flex justify-center"
          >
            <span class="primary--text total">Total: </span><span class="black--text total pl-3">R$ {{ SomatotalProduto }}</span>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-card>
</template>
<script>
  import ajustegrupoRep from './../../components/services/ajusteprazogrupoRepository'
  export default {
    props: {
      dados: {
        type: Object,
        required: true,
      },
      prazo: {
        type: Number,
        required: true,
      },
      codigopreco: {
        type: Number,
        required: true,
      },
      tipoorcamento: {
        type: String,
        required: true,
      },
      preorcamento: {
        type: Boolean,
        default: false,
      },
    },
    data () {
      return {
        unidadeSelecionanda: '',
        errorAjuste: '',
        selectUnidade: ['Und', 'Kg'],
        valorUnitarioPorCentagem: '0,00',
        vlUnitario: 0.00,
        vlUnitarioBanco: 0.00,
        totalGeral: 0.00,
        qtd: '0,00',
        currency: {
          locale: 'pt-br',
          currency: null,
          valueAsInteger: false,
          distractionFree: false,
          precision: 2,
          autoDecimalMode: true,
          allowNegative: false,
        },
        currencyAjuste: {
          currency: null,
          valueAsInteger: false,
          distractionFree: false,
          precision: 2,
          autoDecimalMode: true,
          allowNegative: false,
        },
        maxajuste: 0,
        valorajuste: '0',
        disabledAjuste: true,
        pesoTotalVenda: 0,
        empresa: this.$store.getters.empresa.nome.toUpperCase(),
      }
    },
    computed: {
      SomatotalProduto: function () {
        if (parseFloat(this.qtd.replace('.', '').replace(',', '.')) > 0.00) {
          if (this.vlUnitario > 0) {
            return this.formatPrice((parseFloat(this.qtd.replace('.', '').replace(',', '.')) * this.vlUnitario))
          } else {
            return '0,00'
          }
        } else {
          return '0,00'
        }
      },
    },
    watch: {
      // valorajuste: function (value) {
      //   if (this.codigopreco === 2) {
      //     this.errorAjuste = ''
      //     this.onCalcularAjuste(value)
      //   } else if (this.maxajuste >= parseInt(value)) {
      //     this.errorAjuste = ''
      //     this.onCalcularAjuste(value)
      //   } else {
      //     this.errorAjuste = 'Ajuste máximo ' + this.maxajuste
      //   }
      // },
    },
    created () {
      this.getAjuste()
    },
    mounted () {
      this.vlUnitario = this.codigopreco === 0 ? this.dados.vlUnitario : this.codigopreco === 1 ? this.dados.vlUnitario2 : this.codigopreco === 3 ? this.dados.vlUnitario3 : this.empresa === 'JUAÇO' ? this.dados.vlUnitario2 : this.dados.vlUnitario
      this.vlUnitarioBanco = this.vlUnitario
      this.valorUnitarioPorCentagem = this.formatPrice(this.vlUnitario)
      this.unidadeSelecionanda = this.dados.cdUnidade

      this.$ci.setValue(this.$refs.txtValorUnitarioAjuste, this.vlUnitario)
    },
    methods: {
      formatPrice (value) {
        var val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      },
      async getAjuste () {
        this.$store.commit('setSplashScreen', true)
        await ajustegrupoRep.get(this.prazo, this.dados.cdGrupo, this.dados.item).then(response => {
          if (response.data.status === 'sucesso') {
            this.maxajuste = response.data.dados.vlajuste
            if (response.data.dados.vlajuste === 0) this.disabledAjuste = true
            else this.disabledAjuste = false
            this.$store.commit('setSplashScreen', false)
          } else if (response.data.status === 'mensagem') {
            this.$store.commit('setSplashScreen', false)
            this.maxajuste = 0
          } else if (response.data.status === 'error') {
            this.$store.commit('setSplashScreen', false)
            this.$swal({
              icon: 'error',
              text: response.data.messagem,
            })
          }
        }).catch(error => {
          this.maxajuste = 0
          this.$store.commit('setSplashScreen', false)
          this.$swal({
            icon: 'error',
            text: 'Erro ao consultar o valor de ajuste:\n' + error,
          })
        })
      },
      onSelectUnidade () {
        if (this.dados.cdUnidade.toUpperCase() !== this.unidadeSelecionanda.toUpperCase()) {
          this.onValorUnitarioAtual()
        } else {
          this.vlUnitario = this.dados.vlUnitario
        }
        this.onCalcularAjuste(this.valorajuste)
      },
      onCalcularAjuste (value) {
        if (parseFloat(value) >= 100) {
          this.onValorUnitarioAtual()
          this.vlUnitario = parseFloat(this.formatPrice((this.vlUnitario / (parseFloat(value) * -1) * 100) * -1).replace('.', '').replace(',', '.'))
        } else {
          if (this.vlUnitario <= parseFloat(this.valorUnitarioPorCentagem.replace('.', '').replace(',', '.'))) {
            this.onValorUnitarioAtual()
          }
        }
      },
      onValorUnitarioAtual () {
        if (this.dados.cdUnidade.toUpperCase() !== this.unidadeSelecionanda.toUpperCase()) {
          if (this.codigopreco === 0 || this.codigopreco === 2) {
            this.vlUnitario = parseFloat(this.formatPrice(this.dados.vlUnitario * this.dados.vlPesound).replace('.', '').replace(',', '.'))
            return this.vlUnitario
          } else if (this.codigopreco === 3) {
            this.vlUnitario = parseFloat(this.formatPrice(this.dados.vlUnitario3 * this.dados.vlPesound).replace('.', '').replace(',', '.'))
            return this.vlUnitario
          } else {
            this.vlUnitario = parseFloat(this.formatPrice(this.dados.vlUnitario2 * this.dados.vlPesound).replace('.', '').replace(',', '.'))
            return this.vlUnitario
          }
        } else {
          this.vlUnitario = this.codigopreco === 0 ? this.dados.vlUnitario : this.codigopreco === 1 ? this.dados.vlUnitario2 : this.dados.vlUnitario
          return this.dados.vlUnitario
        }
      },
      onPesoTotal () {
        if (this.dados.cdUnidade.toUpperCase() !== this.unidadeSelecionanda.toUpperCase()) {
          this.pesoTotalVenda = parseFloat(this.formatPrice(parseFloat(this.qtd.replace('.', '').replace(',', '.')) * this.dados.vlPesound).replace('.', '').replace(',', '.'))
        } else {
          if (this.dados.cdUnidade.toUpperCase() !== 'KG') {
            this.pesoTotalVenda = parseFloat(this.formatPrice(parseFloat(this.qtd.replace('.', '').replace(',', '.')) * this.dados.vlPeso).replace('.', '').replace(',', '.'))
          } else {
            this.pesoTotalVenda = parseFloat(this.qtd.replace('.', '').replace(',', '.'))
          }
        }
      },
      onAddProduto () {
        this.onPesoTotal()
        if (this.onValidarAddProduto() && this.onValidarEstoque()) {
          var totalGeral = this.SomatotalProduto
          this.onPesoTotal()
          var item = {
            item: this.dados.item.trim(),
            cdItem: this.dados.cdItem,
            cdSubItem: this.dados.cdSubItem,
            qtVenda: parseFloat(this.qtd.replace('.', '').replace(',', '.')),
            vlUnitario: this.vlUnitario,
            flPendente: false,
            flDevolvido: false,
            cdUndSel: this.unidadeSelecionanda,
            cdPesador: 0,
            qtPendente: 0,
            cdUnidade: this.unidadeSelecionanda,
            vlPesoUnd: 0,
            vlIpi: 0.00,
            vlPercIpi: 0.00,
            vlUnitSemIpi: 0.00,
            flReservado: 0.00,
            vlAjuste: parseFloat(this.valorajuste.replace('.', '').replace(',', '.')),
            vlDesconto: 0,
            flCorte: false,
            vlTabela: this.onValorUnitarioAtual(),
            qtVendaPadrao: parseFloat(this.qtd.replace('.', '').replace(',', '.')),
            vlUnitarioPadrao: this.vlUnitario,
            descricao: this.dados.descricao,
            total: parseFloat(totalGeral.replace('.', '').replace(',', '.')),
            maxajuste: this.maxajuste,
            pesoTotalVenda: this.pesoTotalVenda,
          }
          this.$emit('onAddProduto', item)
        }
      },
      onValidarAddProduto () {
        if (this.qtd === '0,00' || this.qtd === '' || this.qtd === '0') {
          this.$swal({
            icon: 'error',
            text: 'Quantidade Zerada',
          })
          return false
        } else {
          return true
        }
      },
      onSair () {
        this.$emit('onAddSair')
      },
      validarAjuste () {
        if (this.codigopreco === 2) {
          this.errorAjuste = ''
          this.onCalcularAjuste(this.valorajuste.replace('.', '').replace(',', '.'))
        } else if (parseFloat(this.maxajuste) >= parseFloat(this.valorajuste.replace('.', '').replace(',', '.'))) {
          this.errorAjuste = ''
          this.onCalcularAjuste(this.valorajuste.replace('.', '').replace(',', '.'))
        } else {
          this.errorAjuste = 'Ajuste máximo ' + this.maxajuste
        }
        if (parseFloat(this.valorajuste.replace('.', '').replace(',', '.')) !== 0.00) {
          this.valorUnitarioPorCentagem = this.formatPrice(this.vlUnitario)
          this.$ci.setValue(this.$refs.txtValorUnitarioAjuste, this.vlUnitario)
        }
      },
      validarAjustePorValorUnitario () {
        this.onValorUnitarioAtual()
        if (this.vlUnitario >= parseFloat(this.valorUnitarioPorCentagem.replace('.', '').replace(',', '.'))) {
          this.valorajuste = String(this.vlUnitario / (parseFloat(this.valorUnitarioPorCentagem.replace('.', '').replace(',', '.'))) * 100).replace('.', ',')
          // DANIELSON 13/08/2024
          // SETANDO O VALOR NO TEXT DO AJUSTE
          this.$ci.setValue(this.$refs.txtValorAjuste, parseFloat(this.valorajuste.replace('.', '').replace(',', '.')))

          if (parseFloat(this.valorajuste.replace('.', '').replace(',', '.')) > 100) {
            if (this.codigopreco === 2) {
              this.errorAjuste = ''
              this.onCalcularAjuste(this.valorajuste.replace('.', '').replace(',', '.'))
            } else if (parseFloat(this.maxajuste) >= parseFloat(this.valorajuste.replace('.', '').replace(',', '.'))) {
              this.errorAjuste = ''
              this.onCalcularAjuste(this.valorajuste.replace('.', '').replace(',', '.'))
            } else {
              this.errorAjuste = 'Ajuste máximo ' + this.maxajuste
            }
          }
        } else {
          this.valorUnitarioPorCentagem = this.formatPrice(this.vlUnitario)
          this.$ci.setValue(this.$refs.txtValorAjuste, 0.00)
          // console.warn(this.valorUnitarioPorCentagem)
          // this.$swal({
          //   icon: 'warning',
          //   text: 'Valor digitado é maior que o valor unitário de tabela',
          // })
        }
      },
      onValidarEstoque () {
        if (this.tipoorcamento === 'A') {
          // if (this.dados.cdUnidade.toUpperCase() !== this.unidadeSelecionanda.toUpperCase()) {
          if (this.dados.estoque < this.pesoTotalVenda && !this.preorcamento) {
            this.$swal({
              icon: 'error',
              text: 'Quantidade maior que o Estoque Atual:' + this.formatPrice(this.dados.estoque),
            })
            return false
          } else if (this.dados.fisico < this.pesoTotalVenda && !this.preorcamento) {
            this.$swal({
              icon: 'error',
              text: 'Quantidade maior que o Físico Atual:' + this.formatPrice(this.dados.fisico),
            })
            return false
          } return true
          // if (this.dados.estoque < parseFloat(this.qtd.replace('.', '').replace(',', '.')) && !this.preorcamento) {
          //   this.$swal({
          //     icon: 'error',
          //     text: 'Quantidade maior que o Estoque Atual:' + this.formatPrice(this.dados.estoque),
          //   })
          //   return false
          // } else if (this.dados.fisico < parseFloat(this.qtd.replace('.', '').replace(',', '.')) && !this.preorcamento) {
          //   this.$swal({
          //     icon: 'error',
          //     text: 'Quantidade maior que o Físico Atual:' + this.formatPrice(this.dados.fisico),
          //   })
          //   return false
          // } return true
        } else {
          if (this.dados.cdUnidade.toUpperCase() !== this.unidadeSelecionanda.toUpperCase() && !this.preorcamento) {
            // console.warn('Peso Total')
            // console.warn(this.pesoTotalVenda)
            if (this.dados.fisico < this.pesoTotalVenda) {
              this.$swal({
                icon: 'error',
                text: 'Quantidade maior que o físisco Atual:' + this.formatPrice(this.dados.fisico),
              })
              return false
            } else return true
          } else {
            if (this.dados.fisico < parseFloat(this.qtd.replace('.', '').replace(',', '.')) && !this.preorcamento) {
              this.$swal({
                icon: 'error',
                text: 'Quantidade maior que o físisco Atual:' + this.formatPrice(this.dados.fisico),
              })
              return false
            } return true
          }
        }
      },
    },
  }
</script>
<style >
    .total {
        font-size: 25px;
    }
</style>
