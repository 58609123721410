<template>
  <div>
    <div>
      <v-btn
        medium
        outlined
        color="primary"
        class="mt-2 pt-0"
        @click="dialogNovo = !dialogNovo"
      >
        Novo*
        <v-icon class="pl-2">
          mdi-truck-plus-outline
        </v-icon>
      </v-btn>
    </div>
    <v-virtual-scroll
      v-if="aguardarConsulta"
      :benched="benched"
      :items="lista"
      :height="heightListControl"
      :item-height="itemheight"
    >
      <template v-slot:default="{ index, item }">
        <v-list-item
          :key="index"
          class="d-flex align-center animate__animated animate__fadeInDown"
        >
          <v-list-item-content>
            <v-list-item-title>
              <strong>{{ item.nmLocalEntrega }}</strong>
            </v-list-item-title>
            <p> {{ item.nmEnder }} <span class="pl-1" /> {{ item.nrEnder }} <span class="pl-1" /> {{ item.nmComplEnder }} </p>
            <p> {{ item.nmBairro }} </p>
            <p> {{ item.txPontoRefer }} </p>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn
              dense
              fab
              :color="buttonSelecionado === index ? 'green accent-4' : 'primary'"
              small
              :class="buttonSelecionado === index ? 'animate__animated animate__flip' : ''"
              @click="onAddLocalEntrega(item, index)"
            >
              <v-icon color="white">
                mdi-check
              </v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
        <v-divider class="pa=0 ma-0" />
      </template>
    </v-virtual-scroll>
    <v-progress-linear
      v-else
      indeterminate
      color="primary"
    />
    <v-dialog
      v-model="dialogNovo"
      scrollable
      max-width="850"
    >
      <modalNovo
        v-if="dialogNovo"
        :editar="false"
        :cdcpfcgc="cpfcnpj"
        @onSair="onSair"
        @onCarregar="onCarregar"
      />
    </v-dialog>
  </div>
</template>
<script>
  import localentregaRep from './../../components/services/localentregaRepository'
  import modalNovo from './componente-localentrega-novo'
  export default {
    components: {
      modalNovo,
    },
    props: {
      cpfcnpj: {
        type: String,
        required: true,
      },
      itemheight: {
        type: Number,
        default: 105,
      },
    },
    data () {
      return {
        aguardarConsulta: true,
        heightListControl: 350,
        lista: [],
        benched: 200,
        buttonSelecionado: 0,
        dialogNovo: false,
      }
    },
    mounted () {
      this.getLista()
    },
    methods: {
      async getLista () {
        this.$store.commit('setSplashScreen', true)
        await localentregaRep.getList(this.cpfcnpj).then(response => {
          this.$store.commit('setSplashScreen', false)
          if (response.data.status === 'sucesso') {
            setTimeout(() => {
              this.aguardarConsulta = true
              this.lista = response.data.dados
              this.heightListControl = 300
            }, 500)
          } else if (response.data.status === 'mensagem') {
            this.aguardarConsulta = false
            this.heightListControl = 0
            this.$swal({
              icon: 'warning',
              text: response.data.messagem,
            })
          } else if (response.data.status === 'error') {
            this.$swal({
              icon: 'error',
              text: response.data.messagem,
            })
          }
        }).catch(error => {
          this.$store.commit('setSplashScreen', false)
          this.aguardarConsulta = true
          this.heightListControl = 0
          this.$swal({
            icon: 'error',
            text: 'Produto não Existe ou Código digitado incorretamente! \n' + error,
          })
        })
      },
      onAddLocalEntrega (localEntrega, index) {
        this.buttonSelecionado = index
        this.$emit('onAddLocalEntrega', localEntrega)
      },
      async onCarregar () {
        this.dialogNovo = false
        await this.getLista()
      },
      onSair () {
        this.dialogNovo = false
      },
    },
  }
</script>
