<template>
  <v-card>
    <v-card-title>
      Pré-Orçamentos
      <v-spacer />
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Pesquisar"
        single-line
        hide-details
      />
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="listaPreOrcamento"
      :search="search"
    >
      <template v-slot:item.vlProdutos="{ item }">
        {{ formatPrice(item.vlProdutos) }}
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon
          small
          class="mr-2"
          @click="onClickSelect(item)"
        >
          mdi-check
        </v-icon>
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
  import preocamentoRep from './../../../../components/services/preocamentoRepository'
  export default {
    props: {
      preorcamento: {
        type: Boolean,
        default: false,
      },
    },
    data () {
      return {
        search: '',
        listaPreOrcamento: [],
        headers: [
          {
            text: 'Pre-Orçamento',
            align: 'start',
            value: 'orcamentoFormatado',
          },
          { text: 'Cliente.', value: 'nmCliente' },
          { text: 'Prazo', value: 'nmPrazo' },
          { text: 'Vl.Total', value: 'vlProdutos' },
          { text: '', value: 'actions', sortable: false },
        ],
      }
    },
    async created () {
      this.$store.commit('setSplashScreen', true)
      await this.getLista()
      setTimeout(() => {
        // this.$refs.txtcodigo.focus()
        this.$store.commit('setSplashScreen', false)
      }, 1500)
    },
    methods: {
      onClickSelect (value) {
        this.$emit('onSelectPreOrcamento', value)
      },
      async getLista () {
        const now = new Date()
        // Obter o deslocamento do fuso horário de Brasília em milissegundos
        const brazilOffset = -3 * 60 * 60 * 1000 // -3 horas em milissegundos
        const isDaylightSaving = now.toLocaleString('en-US', { timeZone: 'America/Sao_Paulo' }).includes('DST')
        // Ajustar o deslocamento para horário de verão, se aplicável
        const offsetWithDST = isDaylightSaving ? brazilOffset + 60 * 60 * 1000 : brazilOffset
        // Calcular o horário ajustado para Brasília
        const brazilTimestamp = now.getTime() + offsetWithDST
        await preocamentoRep.getLista(brazilTimestamp).then(response => {
          // listaUsuarios = response.data
          this.listaPreOrcamento = response.data
        }).catch(() => {
        })
      },
      formatPrice (value) {
        var val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      },
    },
  }
</script>
