import Repository from './repository'

const resource = '/preorcamento'

export default {

    getLista (data) {
        return Repository.get(`${resource}?data=${data}`)
    },
}
